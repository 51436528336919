<template>
  <v-container fluid>
    <!-- Apontamentos de Produção -->
    <v-row 
  class="px-5" 
  :style="{ marginTop: display === 'xs' ? '180px' : '170px' }"
>
    <v-col sty class=" pb-0 mt-0 mb-0" style="height: 70%;justify-items: center !important;" cols="12" sm="6" md="4" lg="3" xl="2"
      v-if="appStore.type_view == 'apontamentos_producao' && appStore.render.length != 0"
      v-for="(apontamento, index) in appStore.render" :key="apontamento.name" v-bind="apontamento">
      <card_apontamento_aberto :apontamento="apontamento" />
    </v-col>

    <!-- Apontamentos de Parada -->
    <v-col class="pt-3 pb-0" style="justify-items: center !important;" cols="12" sm="6" md="4" lg="3" xl="2"
      v-if="appStore.type_view == 'apontamentos_parada' && appStore.render.length != 0"
      v-for="(apontamento, index) in appStore.render" :key="apontamento.name" v-bind="apontamento">
      <card_apontamento_parada :apontamento="apontamento" />
    </v-col>

    <!-- Ordens de Produção -->
    <v-col style="justify-items: center !important;" cols="12" sm="6" md="4" lg="3" xl="2"
      v-if="appStore.type_view == 'ordens_de_producao' && appStore.render.length != 0"
      v-for="(ordem_de_producao, index) in appStore.render" :key="ordem_de_producao.name" v-bind="ordem_de_producao">
      <card_ordem_de_producao :ordem_de_producao="ordem_de_producao" />
    </v-col>

    <!-- Recursos -->
    <v-col style="justify-items: center !important;  " cols="12" sm="4" md="4" lg="3" xl="2" class=" pb-0 mb-0 mt-3"
      v-if="appStore.type_view == 'recursos' && appStore.render.length != 0" v-for="(recurso, index) in appStore.render"
      :key="recurso.name" v-bind="recurso">
      <card_recurso :recurso="recurso" />
    </v-col>

      <!-- Operadores -->
    <v-col style="justify-items: center !important; " class="operadores pb-0 mb-0 mt-3" cols="12" sm="4" md="4" lg="3"
      xl="2" v-if="appStore.type_view == 'operadores' && appStore.render.length != 0"
      v-for="(operador, index) in appStore.render" :key="operador.name" v-bind="operador">
      <card_operador :operador="operador" />
    </v-col>
    </v-row>
  </v-container>
</template>




<script>
import { useAppStore } from "../stores/app";
import card_apontamento_aberto from "./cards/card_apontamento_aberto.vue";
import card_apontamento_parada from "./cards/card_apontamento_parada.vue";
import card_ordem_de_producao from "./cards/card_ordem_de_producao.vue";
import card_recurso from "./cards/card_recurso.vue";
import card_operador from "./cards/card_operador.vue";
import { useDisplay } from 'vuetify'

export default {
  name: "appOverlayEvents",
  components: { card_operador, card_recurso, card_apontamento_aberto, card_apontamento_parada, card_ordem_de_producao },
  data() {
    return {
      display: useDisplay().name
    }
  },
  computed: {
    appStore() {
      return useAppStore();
    },
  },
};
</script>
<style scoped>
/* .operadores{
  width: 260px
}      b  */
</style>