<template>
    <div class="div-principal"
        :type="appStore.group_by.tipo_dashboard == 'Operadores da Lista' ? 'operador' : 'recurso'">
        <v-row justify="center" align="center">
            <!-- Primeiro Card -->
            <v-col>
                <v-card style="border: 2px solid #212121; border-radius: 10px;" elevation="7" width="470" class="card-principal">
                    <v-card-title>
                        <!-- titulo do card -->
                        <div
                        :style="{
                                color: 'white',
                                backgroundColor: isActive ? 'green' : 'red',
                                borderRadius: '5px',
                                textAlign: 'center',
                                fontSize: '20px',
                                border: '2px solid #212121',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px',
                                padding: '8px',
                            }"
                            
                            >
                            <v-icon 
                                :class="isActive ? 'mdi mdi-play-circle' : 'mdi mdi-pause-circle'"
                                style="font-size: 25px;"
                            ></v-icon>
                             
                             <small style="font-size: 20px;">20/12/2024 10:26:50 </small>
                             <v-icon class="mdi mdi-timer" style="height: 2px;"></v-icon>
                             <small style="font-size: 20px;">50:53:10</small>
                        </div>
                    </v-card-title>
                    <!-- indicadores do card -->
                    <v-card-title style="text-align: center; font-size: 25px;">{{ group_by == "operador" ? card.nome : card.nome}}</v-card-title>
                    <div class="card-body" @click="openModalApontamentoAbertoBody"
                        style="display: flex; flex-direction: row; align-items: center;">
                        <div style="flex: 1; display: flex; justify-content: center; padding: 8px; height: 80px;">
                            <v-img
                                :src="iconPath"
                                alt="imagem operador ou recurso"
                                class="card-img"
                                
                            ></v-img>
                            
                        </div>

                        <div style="flex: 2; display: flex; flex-direction: column; gap: 8px;">
                            <v-card style="background-color: #E0E0E0; color: black;" elevation="5">
                                <v-card-title style="color: black; font-weight: bold; font-size: 18px;">
                                    <v-icon class="mdi mdi-account-outline" style="margin-right: 8px;"></v-icon>
                                    <small style="color: black !important;font-size: 16px;font-weight: 780;">OP:
                                    </small> <small style="font-size: 17px; font-weight: 500;">{{ card.apontamentos &&
                                        card.apontamentos.find(apontamento =>
                                            apontamento.nome_operador)?.nome_operador || ' -' }}</small>

                                </v-card-title>
                            </v-card>
                            <v-card style="background-color: #E0E0E0; color: black;" elevation="5">
                                <v-card-title style="color: black; font-weight: bold; font-size: 18px;">
                                    <v-icon class="mdi mdi-cog-outline" style="margin-right: 8px;"></v-icon>
                                    <small style="color: black !important; font-size: 16px; font-weight: 780;">Recurso:
                                    </small> <small style="font-size: 17px; font-weight: 500;">
                                        {{ card.apontamentos && card.apontamentos.find(apontamento =>
                                            apontamento.desc_recurso)?.desc_recurso || ' -' }}</small>

                                </v-card-title>
                            </v-card>
                            <v-card style="background-color: #E0E0E0; color: black;" elevation="5">
                                <v-card-title style="color: black; font-weight: bold; font-size: 18px;">
                                    <v-icon class="mdi mdi-arrow-left-right-bold-outline"
                                        style="margin-right: 8px;"></v-icon>
                                    <small style="color: black !important; font-size: 16px; font-weight: 780;">Item:
                                    </small> <small style="font-size: 17px; font-weight: 500;">
                                        {{ card.apontamentos && card.apontamentos.find(apontamento =>
                                            apontamento.ordem_de_producao?.item)?.ordem_de_producao.item || ' -' }}
                                    </small>
                                </v-card-title>
                            </v-card>
                            <v-card style="background-color: #E0E0E0;" elevation="5">
                                <v-card-title style="color: black; font-weight: bold; font-size: 18px;">
                                    <v-icon class="mdi mdi-calendar-range" style="margin-right: 8px;"></v-icon>
                                    <small style="color: black !important; font-size: 16px; font-weight: 780;">Data de
                                        Entrega: </small>
                                    <small style="font-size: 17px; font-weight: 500;">
                                        {{ formatDate(card.apontamentos && card.apontamentos.find(apontamento =>
                                            apontamento.ordem_de_producao?.dt_entrega)?.ordem_de_producao.dt_entrega) }}
                                    </small>

                                </v-card-title>
                            </v-card>
                            <v-card style="background-color: #E0E0E0;" elevation="5">
                                <v-card-title style="color: black; font-weight: bold; font-size: 18px;">
                                    <v-icon class="mdi mdi-briefcase-outline" style="margin-right: 8px;"></v-icon>
                                    <small style="color: black !important; font-size: 16px; font-weight: 780;">Cliente:
                                    </small> <small style="font-size: 17px; font-weight: 500;">{{ card.apontamentos &&
                                        card.apontamentos.find(apontamento =>
                                            apontamento.ordem_de_producao?.cliente)?.ordem_de_producao.cliente || ' -'
                                        }}</small>
                                </v-card-title>
                            </v-card>
                        </div>
                        <div v-if="appStore.Modal_Apontamento_Aberto_Body" style="height: 0px;"></div>
                    </div>
                </v-card>
            </v-col>
        </v-row>


    </div>
    <Modal />
</template>

<style scoped>
/* estilização  card e icone*/
.card-principal {
    width: 100%;

    height: auto;
}
.v-card-title > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px;
}

.v-icon {
    font-size: 25px;
}

small {
    font-size: 18px; 
}
</style>

<script>

import { useAppStore } from "../../stores/app";
import Modal from "./modal.vue"
import moment from 'moment';

export default {
    name: "card",
    props: {
        card: Object,
    },
    data() {
        return {
            apontamento: null,
            group_by: useAppStore().group_by.tipo_dashboard == "lista de operadores" ? "operador" : "recurso",
            card: this.card
        }
    },
    computed: {
        appStore() {
            return useAppStore();
        },
        isActive() {
            
            return this.card.status === "ativo";
        },
        iconPath() {
            return this.appStore.group_by.tipo_dashboard === "Operadores da Lista"
                ? "https://cdn-icons-png.flaticon.com/512/4427/4427599.png"
                : "/assets/nxlite/vue/operadores-x-recurso/app/components/icone-recurso/ferramentas.png"
        }
    },
    methods: {
        formatDate(dt_entrega) {
            if (!dt_entrega) {
                return 'Data não disponível';
            }

            const formattedDate = moment(dt_entrega).isValid() ? moment(dt_entrega).format('DD/MM/YYYY') : 'Data inválida';
            return formattedDate;
        }
    },
    created() {
    },
    unmounted() {
        clearInterval(this.interval);
    },
};
</script>
