import { Gantt, Selection, VirtualScroll, Filter, Sort, ColumnMenu, Edit, UndoRedo, Toolbar } from '@syncfusion/ej2-gantt';
import { registerLicense } from "@syncfusion/ej2-base";
import { } from "./es5-datasource.js";

Gantt.Inject(Selection, VirtualScroll, Filter, Sort, ColumnMenu, Edit, UndoRedo, Toolbar);

class SyncFusionGantt {
  constructor({ wrapper, element, data }) {
    try {
      if (!wrapper) { throw new Error("Wrapper is required to initialize SyncFusionGantt."); }

      this.$wrapper = $(wrapper);
      this.element = element || "#Gantt";

      console.log("Wrapper initialized:", wrapper);
      console.warn("Dados: ", data)
      registerLicense("Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH1fdnVURWVeVEB1VkI=");

      var ProjectResources = [
        { resourceId: 1, resourceName: 'Martin Tamer' },
        { resourceId: 2, resourceName: 'Rose Fuller' },
        { resourceId: 3, resourceName: 'Margaret Buchanan' },
        { resourceId: 4, resourceName: 'Fuller King' },
        { resourceId: 5, resourceName: 'Davolio Fuller' },
        { resourceId: 6, resourceName: 'Van Jack' },
        { resourceId: 7, resourceName: 'Fuller Buchanan' },
        { resourceId: 8, resourceName: 'Jack Davolio' },
        { resourceId: 9, resourceName: 'Tamer Vinet' },
        { resourceId: 10, resourceName: 'Vinet Fuller' },
        { resourceId: 11, resourceName: 'Bergs Anton' },
        { resourceId: 12, resourceName: 'Construction Supervisor' }
      ];
      var GanttData = [
        {
          TaskID: 1,
          TaskName: 'Product concept',
          StartDate: new Date('04/02/2024'),
          EndDate: new Date('04/21/2024'),
          subtasks: [
            { TaskID: 2, TaskName: 'Defining the product and its usage', StartDate: new Date('04/02/2024'), Duration: 3, Progress: 30 },
            { TaskID: 3, TaskName: 'Defining target audience', StartDate: new Date('04/02/2024'), Duration: 3 },
            { TaskID: 4, TaskName: 'Prepare product sketch and notes', StartDate: new Date('04/02/2024'), Duration: 2, Predecessor: '2', Progress: 30 },
          ]
        },
        {
          TaskID: 5, TaskName: 'Concept approval', StartDate: new Date('04/02/2024'), Duration: 0, Predecessor: '3,4',
          Indicators: [
            {
              'date': new Date('04/10/2024'),
              'name': 'Design Phase',
              'tooltip': 'Design phase completed',
              'iconClass': 'okIcon e-icons'
            }
          ],
        },
        {
          TaskID: 6,
          TaskName: 'Market research',
          StartDate: new Date('04/02/2024'),
          EndDate: new Date('04/21/2024'),
          subtasks: [
            {
              TaskID: 7,
              TaskName: 'Demand analysis',
              StartDate: new Date('04/04/2024'),
              EndDate: new Date('04/21/2024'),
              subtasks: [
                {
                  TaskID: 8, TaskName: 'Customer strength', StartDate: new Date('04/04/2024'), Duration: 4,
                  Predecessor: '5', Progress: 30
                },
                { TaskID: 9, TaskName: 'Market opportunity analysis', StartDate: new Date('04/04/2024'), Duration: 4, Predecessor: '5' }
              ]
            },
            {
              TaskID: 10, TaskName: 'Competitor analysis', StartDate: new Date('04/04/2024'), Duration: 4,
              Predecessor: '7, 8', Progress: 30
            },
            { TaskID: 11, TaskName: 'Product strength analsysis', StartDate: new Date('04/04/2024'), Duration: 4, Predecessor: '9' },
            {
              TaskID: 12, TaskName: 'Research complete', StartDate: new Date('04/04/2024'), Duration: 0, Predecessor: '10',
              Indicators: [
                {
                  'date': new Date('04/27/2024'),
                  'name': 'Research completed',
                  'tooltip': 'Research completed',
                  'iconClass': 'description e-icons'
                }
              ],
            }
          ]
        }
      ];

      const ganttChart = new Gantt({
        dataSource: GanttData,
        height: 'calc(100vh - 120px)',
        highlightWeekends: true,
        showColumnMenu: true,
        enableContextMenu: true,
        allowFiltering: true,
        enableUndoRedo: true,
        allowSorting: true,
        allowResizing: true,
        allowReordering: true,
        allowSelection: true,
        selectionSettings: { mode: "Row", type: "Single" },
        filterSettings: { type: "Menu" },
        showTodayMarker: true,
        enableVirtualization: true,
        taskFields: {
          id: 'TaskID',
          name: 'TaskName',
          startDate: 'StartDate',
          endDate: 'EndDate',
          duration: 'Duration',
          progress: 'Progress',
          dependency: 'Predecessor',
          child: 'subtasks'
        },
        editSettings: {
          allowAdding: true,
          allowEditing: true,
          allowDeleting: true,
          allowFiltering: true,
          allowTaskbarEditing: true,
          showDeleteConfirmDialog: true,
          fields: {
            Status: {
              type: "Dropdown",
              params: {
                dataSource: ["Not Started", "In Progress", "Completed", "On Hold"],
                placeholder: "Selecione o Status",
                allowFiltering: true,
              }
            }
          }
        },
        splitterSettings: {
          position: '50%',
          columnIndex: 2
        },
        columns: [
          { field: 'TaskID', headerText: 'ID', width: 100 },
          { field: 'TaskName', headerText: 'Nome', width: 250 },
          { field: 'StartDate', headerText: 'Início', format: 'MM/dd/yyyy', type: 'date' },
          { field: 'EndDate', headerText: 'Termino', format: 'MM/dd/yyyy', type: 'date' },
          { field: 'Duration', headerText: 'Duração', width: 120 },
          { field: 'Progress', headerText: 'Progresso (%)', width: 150 },
          { field: 'Predecessor', headerText: 'Dependência' }
        ],
        toolbar: [
          'Add', 'Edit', 'Update', 'Delete', 'Cancel', 'Search',
          'ExpandAll', 'CollapseAll', 'Undo', 'Redo'
        ],
        undoRedoActions: ['Sorting', 'Add', 'ColumnReorder', 'ColumnResize', 'ColumnState', 'Delete', 'Edit', 'Filtering', 'Indent', 'Outdent', 'NextTimeSpan', 'PreviousTimeSpan', 'RowDragAndDrop', 'Search'],
        treeColumnIndex: 1,
        labelSettings: {
          leftLabel: 'TaskName',
          rightLabel: 'Progress'
        },
        projectStartDate: new Date('03/24/2024'),
        projectEndDate: new Date('07/06/2024'),
        resources: ProjectResources
      });

      ganttChart.appendTo(this.element);
      console.log("ganttchart rendered successfully.");
    } catch (e) { console.error("Error initializing SyncFusionGantt:", e); }
  }
}

frappe.provide("frappe.ganttchart");
frappe.ganttchart.SyncFusionGantt = SyncFusionGantt;

export default SyncFusionGantt;