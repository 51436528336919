<template>
    <charts_recursos_por_status />
</template>

<script>
import { registerLicense } from "@syncfusion/ej2-base";

import { useAppFabricaAtual } from "../../../stores/appFabricaAtual";

registerLicense(
        "Ngo9BigBOggjHTQxAR8/V1NCaF1cWmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjUX9acH1XR2BZVEZ1Xw=="
      )
import charts_recursos_por_status from '../../producao/charts/recursos_por_status.vue';
export default {
    name: "maquinasStatus",
    components: {
        charts_recursos_por_status
    },
    created(){
        useAppFabricaAtual().listar_recursos_abertos()
        frappe.realtime.on('apontamento_app', (data) => {
            useAppFabricaAtual().listar_recursos_abertos().then(()=>{
            useAppFabricaAtual().listar_recursos_abertos()
        })})

      frappe.realtime.on('recurso', (data) => {
        console.log('teste',data)
        useAppFabricaAtual().listar_recursos_abertos().then(()=>{
            useAppFabricaAtual().listar_recursos_abertos()
        })})
    }
}
</script>

<style scoped></style>