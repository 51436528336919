import { Gantt, Selection, VirtualScroll, Filter, Sort, ColumnMenu, Edit, UndoRedo, Toolbar } from '@syncfusion/ej2-gantt';
import { registerLicense } from "@syncfusion/ej2-base";
import { } from "./es5-datasource.js";

import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-gantt/styles/material.css';

Gantt.Inject(Gantt, Selection, VirtualScroll, Filter, Sort, ColumnMenu, Edit, UndoRedo, Toolbar);

class SyncFusionGantt {
  constructor({ wrapper, element, data }) {
    try {
      if (!wrapper) { throw new Error("Wrapper is required to initialize SyncFusionGantt."); }

      this.$wrapper = $(wrapper);
      this.element = element || "#Gantt";

      console.log("Wrapper initialized:", wrapper);
      console.log("Dados: ", data)
      ej.registerLicense("ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5SdkdiXXpfc3dXT2NV");

      const GanttData = Array.isArray(data) ? data.map(task => {
        return {
          TaskID: task.TaskID,
          TaskName: task.TaskName,
          StartDate: new Date(task.StartDate),
          EndDate: new Date(task.EndDate),
          subtasks: task.subtasks ? task.subtasks.map(subtask => ({
            TaskID: subtask.TaskID,
            TaskName: subtask.TaskName,
            StartDate: new Date(subtask.StartDate),
            Duration: subtask.Duration || 0,
            Progress: subtask.Progress || 0,
            Predecessor: subtask.Predecessor || null,
          })) : []
        };
      }) : [];

      console.log("Dados estruturados para o Gantt:", GanttData);

      const currentYear = new Date().getFullYear();
      const ganttChart = new Gantt({
        dataSource: GanttData,
        height: '500px',
        highlightWeekends: true,
        showColumnMenu: true,
        enableContextMenu: true,
        allowFiltering: true,
        enableUndoRedo: true,
        allowSorting: true,
        allowResizing: true,
        allowReordering: true,
        allowSelection: true,
        selectionSettings: { mode: "Row", type: "Single" },
        filterSettings: { type: "Menu" },
        showTodayMarker: true,
        enableVirtualization: true,
        taskFields: {
          id: 'TaskID',
          name: 'TaskName',
          startDate: 'StartDate',
          endDate: 'EndDate',
          duration: 'Duration',
          progress: 'Progress',
          dependency: 'Predecessor',
          child: 'subtasks'
        },
        editSettings: {
          allowAdding: true,
          allowEditing: true,
          allowDeleting: true,
          allowFiltering: true,
          allowTaskbarEditing: true,
          showDeleteConfirmDialog: true,
          fields: {
            Status: {
              type: "Dropdown",
              params: {
                dataSource: ["Not Started", "In Progress", "Completed", "On Hold"],
                placeholder: "Selecione o Status",
                allowFiltering: true,
              }
            }
          }
        },
        splitterSettings: {
          position: '50%',
          columnIndex: 2
        },
        columns: [
          { field: 'TaskID', headerText: 'ID', width: 100 },
          { field: 'TaskName', headerText: 'Nome', width: 250 },
          { field: 'StartDate', headerText: 'Início', format: 'MM/dd/yyyy', type: 'date' },
          { field: 'EndDate', headerText: 'Termino', format: 'MM/dd/yyyy', type: 'date' },
          { field: 'Duration', headerText: 'Duração', width: 120 },
          { field: 'Progress', headerText: 'Progresso (%)', width: 150 },
          { field: 'Predecessor', headerText: 'Dependência' }
        ],
        toolbar: ['Add', 'Edit', 'Update', 'Delete', 'Cancel', 'Search', 'ExpandAll', 'CollapseAll', 'Undo', 'Redo'],
        undoRedoActions: ['Sorting', 'Add', 'ColumnReorder', 'ColumnResize', 'ColumnState', 'Delete', 'Edit', 'Filtering', 'Indent', 'Outdent', 'NextTimeSpan', 'PreviousTimeSpan', 'RowDragAndDrop', 'Search'],
        treeColumnIndex: 1,
        labelSettings: {
          leftLabel: 'TaskName',
          rightLabel: 'Progress'
        },
        gridLines: "Both",
        holidays: [
          { from: `${currentYear}-01-02`, label: "Início do Novo" },
          { from: `${currentYear + 1}-01-01`, label: "Fim do Ano" },
        ],
        projectStartDate: new Date('03/24/2024'),
        projectEndDate: new Date('07/06/2024'),
        // resources: ProjectResources
      });

      ganttChart.appendTo(this.$wrapper[0]);
      console.log("ganttchart rendered successfully.");
    } catch (e) { console.error("Error initializing SyncFusionGantt:", e); }
  }
}

frappe.provide("frappe.gantt_chart");
frappe.gantt_chart.SyncFusionGantt = SyncFusionGantt;

export default SyncFusionGantt;