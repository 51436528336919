<template>
    <v-card>
        <v-toolbar height="100%" class="py-1">           
            <v-row justify="center">
                <v-col cols="12" sm="12" md="2" class="pl-4" style="align-content: center;">
                    <v-menu transition="scale-transition">
                        <template v-slot:activator="{ props }">
                            <v-btn color="white" v-bind="props" style="background-color: #121935;">
                                <small style="text-wrap: wrap;" class="font-weight-bold">Recarregar em: {{ timeSelect
                                    }}</small>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(item, i) in items" :key="i" style="cursor: pointer;">
                                <v-btn width="100%" @click="onclickTime(item.title)">{{ item.title }}</v-btn>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
                <v-col v-if="filtroGrupos" cols="12" :md="!filtroMaquinas || !filtroTurnos || !filtroData ? '3' : '2'"
                    sm="6" class="align-center">
                    <v-select style="color: black !important; font-weight: bold;" density="compact" hide-details="false"
                        clearable bg-color="white" v-model="selectedGroup" :items="groupData" label="Grupos" multiple>
                        <template v-slot:prepend-item>
                            <v-list-item title="Todos" @click="selectAllGroup">
                                <template v-slot:prepend>
                                    <v-checkbox-btn :model-value="allGroup"></v-checkbox-btn>
                                </template>
                            </v-list-item>
                        </template>
                        <template v-slot:selection="{ item, index }">
                            <div v-if="index < 1">
                                <span class="text-truncate">{{ item.title }}</span>
                            </div>
                            <span v-if="index === 1" class="font-weight-bold text-caption align-self-center">
                                (+{{ selectedGroup.length - 1 }})
                            </span>
                        </template>
                    </v-select>
                </v-col>

                <v-col v-if="filtroMaquinas" cols="12" :md="!filtroTurnos || !filtroGrupos || !filtroData ? '3' : '2'"
                    sm="6" class="align-center">
                    <v-select style="color: black !important; font-weight: bold;" class="text-no-wrap" density="compact"
                        hide-details="false" clearable bg-color="white" v-model="selectedMachine" :items="machineData"
                        item-title="MachineName" item-value="MachineId" label="Máquinas" multiple>
                        <template v-slot:prepend-item>
                            <v-list-item title="Todos" @click="selectAllMachine">
                                <template v-slot:prepend>
                                    <v-checkbox-btn :model-value="allMachine"></v-checkbox-btn>
                                </template>
                            </v-list-item>
                        </template>
                        <template v-slot:selection="{ item, index }">
                            <div v-if="index < 1">
                                <span class="text-truncate">{{ item.title }}</span>
                            </div>
                            <span v-if="index === 1" class="font-weight-bold text-caption align-self-center">
                                (+{{ selectedMachine.length - 1 }})
                            </span>
                        </template>
                    </v-select>
                </v-col>

                <v-col v-if="filtroTurnos" cols="12" :md="!filtroMaquinas || !filtroGrupos || !filtroData ? '3' : '2'"
                    sm="6" class="align-center">
                    <v-select style="color: black !important; font-weight: bold; text-wrap: nowrap !important;"
                        density="compact" hide-details="false" clearable bg-color="white" v-model="selectedShift"
                        :items="shiftData" label="Turnos" multiple>
                        <template v-slot:prepend-item>
                            <v-list-item title="Todos" @click="selectAllShift">
                                <template v-slot:prepend>
                                    <v-checkbox-btn :model-value="allShift"></v-checkbox-btn>
                                </template>
                            </v-list-item>


                        </template>
                        <template v-slot:selection="{ item, index }">
                            <div v-if="index < 1">
                                <span class="text-truncate">{{ item.title }}</span>
                            </div>
                            <span v-if="index === 1" class="font-weight-bold text-caption align-self-center">
                                (+{{ selectedShift.length - 1 }})
                            </span>
                        </template>
                    </v-select>
                </v-col>
                <v-col v-if="filtroData" cols="12" sm="6" md="3" lg="3" class="d-flex align-center">
                    <ejs-daterangepicker ref="DateRangePicker" placeholder="Selecionar Data" :format="'dd/MM/yyyy'"
                        v-model="selectedDate" @focus="showCalendar">
                        <e-presets>
                            <e-preset label="Esta Semana" :start='weekStartDate' :end='weekEndDate'></e-preset>
                            <e-preset label="Este Mês" :start='monthStartDate' :end='monthEndDate'></e-preset>
                            <e-preset label="Último Mês" :start='lastMonthStartDate' :end='lastMonthEndDate'></e-preset>
                            <e-preset label="Último Ano" :start='lastYearStartDate' :end='lastYearEndDate'></e-preset>
                        </e-presets>
                    </ejs-daterangepicker>
                </v-col>
                <v-col cols="12" sm="6" md="1" class="d-flex align-center px-0 pr-4">
                    <v-btn style="background-color: #04b834;" color="white"
                        @click='onGetGraficInformation'>Aplicar</v-btn>
                </v-col>
            </v-row>
        </v-toolbar>
    </v-card>
    <div v-if="filtroDesempenho" class="mt-1">
        <v-row class="d-flex justify-center">
               <v-col cols="3" class="d-flex justify-center align-center px-1">
                   <v-select
                       hide-details="false"
                       v-model="selectedGroupBy"
                       style="color: black !important; font-weight: bold;"
                       label="Visualizar por:"
                       :items="itensGroupBy"
                       density="compact"
                   ></v-select>
               </v-col>
               <v-col cols="3" class="d-flex justify-center align-center px-1">
                   <v-select
                       hide-details="false"
                       style="color: black !important; font-weight: bold;"
                       v-model="selectedType"
                       label="Agrupar por:"
                       :items="itensType"
                       density="compact"
                   ></v-select>
               </v-col>
               <v-col cols="3" class="d-flex justify-center align-center px-1">
                   <v-number-input
                       hide-details="false"
                       style="color: black !important; font-weight: bold;"
                       controlVariant="default"
                       :max="100"
                       :min="0"
                       v-model="meta"
                       density="compact"
                       label="Meta"
                       :hideInput="false"
                       :inset="false">
                   </v-number-input>
               </v-col>
       </v-row>
    </div>
</template>

<script>
import { useAppCharts } from "../../../../stores/appCharts";
import { useAppMaquinas } from "../../../../stores/appMaquinas";
import { MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";
import { DateRangePickerComponent, PresetDirective, PresetsDirective } from '@syncfusion/ej2-vue-calendars';
import { formatDateBRToISO } from "../../../utils/funcoes";
import { formatedDateBR } from "../../../utils/funcoes";
import { VNumberInput } from 'vuetify/labs/VNumberInput'

export default {
    name: 'toolbarCharts',
    emits: ["toolbarFiltros"],
    props: {
        filtroMaquinas: {
            type: Boolean,
            default: true
        },
        filtroGrupos: {
            type: Boolean,
            default: false
        },
        filtroTurnos: {
            type: Boolean,
            default: true
        },
        filtroData: {
            type: Boolean,
            default: true
        },
        filtroDesempenho: {
            type: Boolean,
            default: false
        }
    },
    components: {
        'ejs-multiselect': MultiSelectComponent,
        "ejs-daterangepicker": DateRangePickerComponent,
        'e-presets': PresetsDirective,
        'e-preset': PresetDirective,
        VNumberInput
    },
    data() {
        let year = new Date().getFullYear();
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return {
            weekStartDate: new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString()),
            weekEndDate: new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
                - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString()),
            monthStartDate: new Date(new Date(new Date().setDate(1)).toDateString()),
            monthEndDate: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).toDateString()),
            lastMonthStartDate: new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString()),
            lastMonthEndDate: new Date(new Date(new Date().setDate(0)).toDateString()),
            lastYearStartDate: new Date(year - 1, 1, 1).toDateString(),
            lastYearEndDate: new Date(year - 1, 11, 31).toDateString(),
            selectedDate: [],
            selectedGroup: [],
            selectedMachine: [],
            selectedShift: [],
            selShift: [],
            selectedStartDate: '',
            selectedEndDate: '',
            selectedMachinesURL: '',
            selectedGroupsURL: '',
            selectedShiftsURL: '',
            selectedDateURL: '',
            selectedTimeURL: '',
            type: 'maquinas',
            typeURL: '',
            groupBy: 'dia',
            groupByURL: '',
            meta: 85,
            metaURL: '',
            itensGroupBy: ["Dia", "Semana", "Mês", "Ano"],
            itensType: [turno="Turnos", maquinas="Máquinas"],
            selectedType: 'Máquinas',
            selectedGroupBy: 'Dia',
            hoje: today,
            groupData: ['Grupo1', 'Grupo2', 'Grupo3', 'Grupo4'],
            machineData: [],
            shiftData: [],
            resourceMaquinas: [],
            timeSelect: '',
            items: [
                { title: 'OFF' },
                { title: 'AUTO' },
                { title: '5S' },
                { title: '10S' },
                { title: '30S' },
                { title: '1M' },
                { title: '5M' },
                { title: '10M' },
                { title: '15M' },
                { title: '30M' },
                { title: '1H' },
                { title: '2H' },
                { title: '1D' },
            ],
        };
    },
    async created() {
        await this.getDataTurnos()
        this.getTurnosUrl()
        await this.getArrayMachines()
        this.getMachinesUrl()
        await this.getDates()
        await this.getTypeURL()
        await this.getGroupByURL()
        await this.onGetGraficInformation()
        this.getReload()

    },
    computed: {
        allGroup() {
            return this.selectedGroup.length === this.groupData.length
        },

        allShift() {
            return this.selectedShift.length === this.shiftData.length
        },

        allMachine() {
            return this.selectedMachine.length === this.machineData.length
        },
        appCharts() {
            return useAppCharts();
        },
        appMaquinas() {
            return useAppMaquinas();
        },

    },
    watch: {
        selectedGroup(newVal) {
            this.$emit('toolbarFiltros', 'group', newVal)
        },

        selectedType(newVal) {
            console.log('type', newVal)
            switch(newVal) {
                case 'Máquinas': 
                    this.type = 'maquinas'
                    break
                case 'Turnos': 
                    this.type = 'turnos'
                    break
            }
        },

        type(newVal) {
            if(newVal == 'maquinas') {
                this.typeURL = ''
            } else {
                this.typeURL = `&type=${newVal}`
            }

            this.$emit('toolbarFiltros', 'type', newVal)
        },

        selectedGroupBy(newVal) {
            switch(newVal) {
                case 'Dia': 
                    this.groupBy = 'dia'
                    break
                case 'Semana': 
                    this.groupBy = 'semana'
                    break
                case 'Mês': 
                    this.groupBy = 'mes'
                    break
                case 'Ano': 
                    this.groupBy = 'ano'
                    break
            }
        },

        groupBy(newVal) {
            if(newVal == 'dia') {
                this.groupByURL = ''
            } else {
                this.groupByURL = `&groupBy=${newVal}`
            }

            this.$emit('toolbarFiltros', 'groupBy', newVal)
        },

        meta(newVal) {
            this.meta = newVal
            if(newVal == 85) {
                this.metaURL = ''
            } else {
                this.metaURL = `&meta=${newVal}`
            }
        },

        shiftData(newVal) {
            if(this.selectedShift.length == 0 ) {
                this.selectedShift = newVal
            }
        },  

        selectedMachine(newVal) {

            this.$emit('toolbarFiltros', 'machine', newVal)
        },

        selectedShift(newVal) {
            this.$emit('toolbarFiltros', 'shift', newVal)
        },

        selectedDate(newVal) {
            if (newVal) {
                //variáveis criadas para evitar que na hora da comparação ele puxe os dados da memória
                const newValStart = new Date(newVal[0]).getTime();
                const newValEnd = new Date(newVal[1]).getTime();
                const hojeTime = new Date(this.hoje).getTime();

                if (newValStart === hojeTime && newValEnd === hojeTime) {
                    this.selectedDateURL = '';
                    this.selectedStartDate = newVal[0];
                    this.selectedEndDate = newVal[1];
                } else {
                    this.selectedDateURL = `&date=${formatedDateBR(newVal[0])}-${formatedDateBR(newVal[1])}`;
                    this.selectedStartDate = newVal[0];
                    this.selectedEndDate = newVal[1];
                }
            }
        },

        selectedStartDate(newVal) {
            this.$emit('toolbarFiltros', 'startDate', formatedDateBR(newVal))
        },

        selectedEndDate(newVal) {
            this.$emit('toolbarFiltros', 'endDate', formatedDateBR(newVal))
        },

        timeSelect(newVal) {
            if (newVal == '15M') {
                this.selectedTimeURL = ''
            } else {
                this.selectedTimeURL = `&reload=${newVal}`
            }
        }
    },
    methods: {
        onclickTime(time) {
            this.timeSelect = time;
        },

        newUrlSel() {
            if (this.selectedShift.length > 0) {
                var _filter_turnos = this.selectedShift.map(shift => '&shift=' + shift).join('');
            }
            _filter_turnos = this.selectedShift.length == this.shiftData.length ? '' : _filter_turnos

            if(this.selectedMachine.length > 0) {
                var _filter_machines = this.selectedMachine.map(machine => '&machine=' + machine).join('')
            }
            _filter_machines = this.selectedMachine.length == this.machineData.length ? '' : _filter_machines


            const newUrl = `?${_filter_machines}${_filter_turnos}${this.selectedDateURL}${this.typeURL}${this.groupByURL}${this.metaURL}`;

            window.history.replaceState(null, '', newUrl);

        },

        async onGetGraficInformation() {

            if (!this.selectedDate) {
                this.selectedDate = [this.hoje, this.hoje]
            }

            if (this.selectedMachine.length == 0) {
                this.selectedMachine = this.machineData
            }

            if (this.selectedShift.length == 0) {
                this.selectedShift = this.shiftData
            }

            this.newUrlSel()

            this.$emit('onGetGraficInformation', 'onclick')
        },

        showCalendar() {
            this.$refs.DateRangePicker.show();  // Exibe o calendário
        },

        selectAllGroup() {
            if (this.allGroup) {
                this.selectedGroup = []
            } else {
                this.selectedGroup = this.groupData.slice()
            }
        },
        selectAllMachine() {
            if (this.allMachine) {
                this.selectedMachine = []
            } else {
                this.selectedMachine = this.machineData.map(machine => machine.MachineId).slice()
            }
        },

        selAllShift() {
            if (this.allShift) {
                this.selShift = []
            } else {
                this.selShift = this.shiftData.slice()
            }
        },
        selectAllShift() {
            if (this.allShift) {
                this.selectedShift = []
            } else {
                this.selectedShift = this.shiftData.slice()
            }
        },
        async getDates() {
            if ((window.location.href.includes('&date='))) {
                let urlParts = window.location.href.split('&date=');
                let datesURL = urlParts.slice(1).map(part => part.split('&')[0])
                let startAndEnd = datesURL[0].split('-')
                this.selectedDate = [formatDateBRToISO(startAndEnd[0]), formatDateBRToISO(startAndEnd[1])]
                this.selectedStartDate = this.selectedDate[0]
                this.selectedEndDate = this.selectedDate[1]
            } else {
                this.selectedDate = [this.hoje, this.hoje]
            };

        },

        async getDataTurnos() {
            await this.appCharts.listar_turnos().then(() => {
                this.$nextTick(() => {
                    this.shiftData = this.appCharts.shiftData;
                });
            }).catch(error => {
                console.error("Erro ao carregar os turnos", error);
            })
        },

        getTurnosUrl() {
            let urlShift = []

            if (window.location.href.includes('&shift=')) {
                let urlParts = window.location.href.split('&shift=');
                urlShift = urlParts.slice(1).map(part => {
                    let shift = part.split('&')[0].replace(/\+/g, ' ');
                    return decodeURIComponent(shift);
                }).filter(shift => shift.trim() !== '');
            } else {
                urlShift = []
            }

            this.selectedShift = urlShift
        },

        async getTypeURL() {
            let urlType = ''
            let urlSelectedType = ''


            if (window.location.href.includes('&type=')){
                let urlData = null
                let urlParts = window.location.href.split('&type=');
                urlData = urlParts.slice(1).map(part => part.split('&')[0]);
                urlType = urlData[0]
                console.log('urlTYpe', urlType)
            } else {
                urlType = 'maquinas'
            }
            switch(urlType) {
                case 'maquinas': 
                    urlSelectedType = 'Máquinas'
                    console.log('teste maquina')
                    break
                case 'turnos': 
                    urlSelectedType = 'Turnos'
                    console.log('teste tunro')
                    break
            }
            this.type = urlType
            this.selectedType = urlSelectedType
            console.log('selected Type', this.selectedType)
        },

        async getGroupByURL() {
            let urlGroupBy = ''
            let urlSelectedGroupBy = ''

            if (window.location.href.includes('&groupBy=')){
                let urlData = null
                let urlParts = window.location.href.split('&groupBy=');
                urlData = urlParts.slice(1).map(part => part.split('&')[0]);
                urlGroupBy = urlData[0]
            } else {
                urlGroupBy = 'dia'
            }

            switch(urlGroupBy) {
                case 'dia': 
                    urlSelectedGroupBy = 'Dia'
                    break
                case 'semana': 
                    urlSelectedGroupBy = 'Semana'
                    break
                case 'mes': 
                    urlSelectedGroupBy = 'Mês'
                    break
                case 'ano': 
                    urlSelectedGroupBy = 'Ano'
                    break
            }

            this.selectedGroupBy = urlSelectedGroupBy
            this.groupBy = urlGroupBy

        },
        

        async getArrayMachines() {
           await this.appMaquinas.listar_maquinas().then(() => {
                this.$nextTick(() => {
                    this.machineData = this.appMaquinas.resourceMaquinas.map(
                        maquina => ({
                            MachineName: maquina.nome,
                            MachineId: maquina.id
                        }));
                });
            }).catch(error => {
                console.error("Erro ao máquinas", error)
            })
        },

        getMachinesUrl() {
            let urlMachines = []

            if (window.location.href.includes('&machine=')) {
                let urlParts = window.location.href.split('&machine=');
                urlMachines = urlParts.slice(1).map(part => part.split('&')[0]);
            } else {
                urlMachines =  this.machineData.map(machine => machine.MachineId)
            };
            
            this.selectedMachine = urlMachines


        },

        getReload() {
            if (window.location.href.includes('&reload=')) {
                let urlParts = window.location.href.split('&reload=');
                this.timeSelect = urlParts[1]
            } else {
                this.timeSelect = '15M'
            }
        }
    },
};
</script>

<style scoped></style>