import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  style: {"text-wrap":"wrap"},
  class: "font-weight-bold"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "text-truncate" }
const _hoisted_4 = {
  key: 1,
  class: "font-weight-bold text-caption align-self-center"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "text-truncate" }
const _hoisted_7 = {
  key: 1,
  class: "font-weight-bold text-caption align-self-center"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "text-truncate" }
const _hoisted_10 = {
  key: 1,
  class: "font-weight-bold text-caption align-self-center"
}
const _hoisted_11 = {
  key: 0,
  class: "mt-1"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_list_item = _resolveComponent("v-list-item")
  const _component_v_list = _resolveComponent("v-list")
  const _component_v_menu = _resolveComponent("v-menu")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_checkbox_btn = _resolveComponent("v-checkbox-btn")
  const _component_v_select = _resolveComponent("v-select")
  const _component_e_preset = _resolveComponent("e-preset")
  const _component_e_presets = _resolveComponent("e-presets")
  const _component_ejs_daterangepicker = _resolveComponent("ejs-daterangepicker")
  const _component_v_row = _resolveComponent("v-row")
  const _component_v_toolbar = _resolveComponent("v-toolbar")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_number_input = _resolveComponent("v-number-input")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_toolbar, {
          height: "100%",
          class: "py-1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { justify: "center" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  sm: "12",
                  md: "2",
                  class: "pl-4",
                  style: {"align-content":"center"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_menu, { transition: "scale-transition" }, {
                      activator: _withCtx(({ props }) => [
                        _createVNode(_component_v_btn, _mergeProps({ color: "white" }, props, { style: {"background-color":"#121935"} }), {
                          default: _withCtx(() => [
                            _createElementVNode("small", _hoisted_1, "Recarregar em: " + _toDisplayString($data.timeSelect), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_list, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.items, (item, i) => {
                              return (_openBlock(), _createBlock(_component_v_list_item, {
                                key: i,
                                style: {"cursor":"pointer"}
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_btn, {
                                    width: "100%",
                                    onClick: $event => ($options.onclickTime(item.title))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                ($props.filtroGrupos)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 0,
                      cols: "12",
                      md: !$props.filtroMaquinas || !$props.filtroTurnos || !$props.filtroData ? '3' : '2',
                      sm: "6",
                      class: "align-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          style: {"color":"black !important","font-weight":"bold"},
                          density: "compact",
                          "hide-details": "false",
                          clearable: "",
                          "bg-color": "white",
                          modelValue: $data.selectedGroup,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.selectedGroup) = $event)),
                          items: $data.groupData,
                          label: "Grupos",
                          multiple: ""
                        }, {
                          "prepend-item": _withCtx(() => [
                            _createVNode(_component_v_list_item, {
                              title: "Todos",
                              onClick: $options.selectAllGroup
                            }, {
                              prepend: _withCtx(() => [
                                _createVNode(_component_v_checkbox_btn, { "model-value": $options.allGroup }, null, 8 /* PROPS */, ["model-value"])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["onClick"])
                          ]),
                          selection: _withCtx(({ item, index }) => [
                            (index < 1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                  _createElementVNode("span", _hoisted_3, _toDisplayString(item.title), 1 /* TEXT */)
                                ]))
                              : _createCommentVNode("v-if", true),
                            (index === 1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_4, " (+" + _toDisplayString($data.selectedGroup.length - 1) + ") ", 1 /* TEXT */))
                              : _createCommentVNode("v-if", true)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue", "items"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["md"]))
                  : _createCommentVNode("v-if", true),
                ($props.filtroMaquinas)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 1,
                      cols: "12",
                      md: !$props.filtroTurnos || !$props.filtroGrupos || !$props.filtroData ? '3' : '2',
                      sm: "6",
                      class: "align-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          style: {"color":"black !important","font-weight":"bold"},
                          class: "text-no-wrap",
                          density: "compact",
                          "hide-details": "false",
                          clearable: "",
                          "bg-color": "white",
                          modelValue: $data.selectedMachine,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.selectedMachine) = $event)),
                          items: $data.machineData,
                          "item-title": "MachineName",
                          "item-value": "MachineId",
                          label: "Máquinas",
                          multiple: ""
                        }, {
                          "prepend-item": _withCtx(() => [
                            _createVNode(_component_v_list_item, {
                              title: "Todos",
                              onClick: $options.selectAllMachine
                            }, {
                              prepend: _withCtx(() => [
                                _createVNode(_component_v_checkbox_btn, { "model-value": $options.allMachine }, null, 8 /* PROPS */, ["model-value"])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["onClick"])
                          ]),
                          selection: _withCtx(({ item, index }) => [
                            (index < 1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                  _createElementVNode("span", _hoisted_6, _toDisplayString(item.title), 1 /* TEXT */)
                                ]))
                              : _createCommentVNode("v-if", true),
                            (index === 1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_7, " (+" + _toDisplayString($data.selectedMachine.length - 1) + ") ", 1 /* TEXT */))
                              : _createCommentVNode("v-if", true)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue", "items"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["md"]))
                  : _createCommentVNode("v-if", true),
                ($props.filtroTurnos)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 2,
                      cols: "12",
                      md: !$props.filtroMaquinas || !$props.filtroGrupos || !$props.filtroData ? '3' : '2',
                      sm: "6",
                      class: "align-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          style: {"color":"black !important","font-weight":"bold","text-wrap":"nowrap !important"},
                          density: "compact",
                          "hide-details": "false",
                          clearable: "",
                          "bg-color": "white",
                          modelValue: $data.selectedShift,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.selectedShift) = $event)),
                          items: $data.shiftData,
                          label: "Turnos",
                          multiple: ""
                        }, {
                          "prepend-item": _withCtx(() => [
                            _createVNode(_component_v_list_item, {
                              title: "Todos",
                              onClick: $options.selectAllShift
                            }, {
                              prepend: _withCtx(() => [
                                _createVNode(_component_v_checkbox_btn, { "model-value": $options.allShift }, null, 8 /* PROPS */, ["model-value"])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["onClick"])
                          ]),
                          selection: _withCtx(({ item, index }) => [
                            (index < 1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                  _createElementVNode("span", _hoisted_9, _toDisplayString(item.title), 1 /* TEXT */)
                                ]))
                              : _createCommentVNode("v-if", true),
                            (index === 1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_10, " (+" + _toDisplayString($data.selectedShift.length - 1) + ") ", 1 /* TEXT */))
                              : _createCommentVNode("v-if", true)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue", "items"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["md"]))
                  : _createCommentVNode("v-if", true),
                ($props.filtroData)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 3,
                      cols: "12",
                      sm: "6",
                      md: "3",
                      lg: "3",
                      class: "d-flex align-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ejs_daterangepicker, {
                          ref: "DateRangePicker",
                          placeholder: "Selecionar Data",
                          format: 'dd/MM/yyyy',
                          modelValue: $data.selectedDate,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.selectedDate) = $event)),
                          onFocus: $options.showCalendar
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_e_presets, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_e_preset, {
                                  label: "Esta Semana",
                                  start: $data.weekStartDate,
                                  end: $data.weekEndDate
                                }, null, 8 /* PROPS */, ["start", "end"]),
                                _createVNode(_component_e_preset, {
                                  label: "Este Mês",
                                  start: $data.monthStartDate,
                                  end: $data.monthEndDate
                                }, null, 8 /* PROPS */, ["start", "end"]),
                                _createVNode(_component_e_preset, {
                                  label: "Último Mês",
                                  start: $data.lastMonthStartDate,
                                  end: $data.lastMonthEndDate
                                }, null, 8 /* PROPS */, ["start", "end"]),
                                _createVNode(_component_e_preset, {
                                  label: "Último Ano",
                                  start: $data.lastYearStartDate,
                                  end: $data.lastYearEndDate
                                }, null, 8 /* PROPS */, ["start", "end"])
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue", "onFocus"])
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                _createVNode(_component_v_col, {
                  cols: "12",
                  sm: "6",
                  md: "1",
                  class: "d-flex align-center px-0 pr-4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      style: {"background-color":"#04b834"},
                      color: "white",
                      onClick: $options.onGetGraficInformation
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Aplicar")
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["onClick"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    ($props.filtroDesempenho)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_component_v_row, { class: "d-flex justify-center" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "3",
                class: "d-flex justify-center align-center px-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    "hide-details": "false",
                    modelValue: $data.selectedGroupBy,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.selectedGroupBy) = $event)),
                    style: {"color":"black !important","font-weight":"bold"},
                    label: "Visualizar por:",
                    items: $data.itensGroupBy,
                    density: "compact"
                  }, null, 8 /* PROPS */, ["modelValue", "items"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, {
                cols: "3",
                class: "d-flex justify-center align-center px-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    "hide-details": "false",
                    style: {"color":"black !important","font-weight":"bold"},
                    modelValue: $data.selectedType,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.selectedType) = $event)),
                    label: "Agrupar por:",
                    items: $data.itensType,
                    density: "compact"
                  }, null, 8 /* PROPS */, ["modelValue", "items"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, {
                cols: "3",
                class: "d-flex justify-center align-center px-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_number_input, {
                    "hide-details": "false",
                    style: {"color":"black !important","font-weight":"bold"},
                    controlVariant: "default",
                    max: 100,
                    min: 0,
                    modelValue: $data.meta,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.meta) = $event)),
                    density: "compact",
                    label: "Meta",
                    hideInput: false,
                    inset: false
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}