import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_toolbarCharts = _resolveComponent("toolbarCharts")
  const _component_multiple_charts_desempenho_da_empresa = _resolveComponent("multiple_charts_desempenho_da_empresa")
  const _component_v_card = _resolveComponent("v-card")
  const _component_v_container = _resolveComponent("v-container")

  return (_openBlock(), _createBlock(_component_v_container, {
    fluid: "",
    height: "100vh"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        height: "100%",
        width: "100%"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_toolbarCharts, {
            onToolbarFiltros: $options.toolbarFiltros,
            onOnGetGraficInformation: $options.onGetGraficInformation,
            type: _ctx.type,
            filtroDesempenho: true
          }, null, 8 /* PROPS */, ["onToolbarFiltros", "onOnGetGraficInformation", "type"]),
          _createVNode(_component_multiple_charts_desempenho_da_empresa, { dataDesempenho: $data.dataDesempenho }, null, 8 /* PROPS */, ["dataDesempenho"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}