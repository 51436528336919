<template>
    <v-container fluid >
    <div class="app apontamento">
        <statusMachine />
        <v-divider class="my-0 pb-1"></v-divider>
        <detalheApontamento />
    </div>
    <funcionarios />
    <ordemProducao />
    <apontamento />
    <observacao />
    <historico />
    <detalhes_os />
    <maquinas />
    <motivo_parada/>
    <load />
    <VSnackbar :timeout="2000" style="font-weight: bold;" v-model="appStore.message.showMessage"
        :color="appStore.message.color" location="top end">
        <v-row justify="space-between" align="center" class="d-flex align-center">
        <small class="ml-1" style="font-size: 14px;">{{ appStore.message.message }}</small>
        <v-icon size="60" color="#FFEA00">mdi-alert-box</v-icon>
        </v-row>
    </VSnackbar>
    </v-container>
  </template>
  
  <script>

  import load from '../../producao/modals/load.vue'
  import statusMachine from '../../producao/statusMachine.vue';
  import detalheApontamento from '../../producao/detalheApontamento.vue';
  import funcionarios from '../../producao/modals/funcionarios.vue';
  import ordemProducao from '../../producao/modals/ordemProducao.vue';
  import apontamento from '../../producao/modals/apontamento.vue';
  import observacao from '../../producao/modals/observacao.vue';
  import historico from '../../producao/modals/historico.vue';
  import detalhes_os from '../../producao/modals/detalhes_os.vue';
  import maquinas from '../../producao/modals/maquinas.vue';
  import motivo_parada from '../../producao/modals/motivos_parada.vue';
  
  import { useAppStore } from '../../../stores/app';
  import { useAppFuncionarios } from '../../../stores/appFuncionarios';
  import { useAppMaquinas } from '../../../stores/appMaquinas';
  import { useAppOrdemProducao } from '../../../stores/appOrdemProducao';
  
  
  
  export default {
    name: 'Home',
    components: {
      statusMachine,
      detalheApontamento,
      funcionarios,
      ordemProducao,
      apontamento,
      observacao,
      historico,
      detalhes_os,
      maquinas,
      motivo_parada,
      load
    },
  
  
    data() {
      return {
        count: 0
      }
    },
    computed: {
      appStore() {
        return useAppStore();
      },
      funcionariosStore() {
        return useAppFuncionarios()
      },
      maquinasStore() {
        return useAppMaquinas()
      },
      opsStore() {
        return useAppOrdemProducao()
      },
      tempExecucao() {
        return this.chartsStore.tempExecucao;
      },
    },
    mounted() {
      frappe.realtime.on('recurso', (data) => {
        this.maquinasStore.update_recurso_realtime(data)
      })
  
      frappe.realtime.on('apontamento_app', (data) => {
        
        // if (Object.keys(data).includes('operador')) if (data.operador) this.funcionariosStore.update_apontamento_realtime(data);
        if (Object.keys(data).includes('recurso')) this.maquinasStore.update_apontamento_realtime(data);
        // (Object.keys(data).includes('ordem_de_producao')) if (data.ordem_de_producao) this.opsStore.update_apontamento_realtime(data);
        // this.chartsStore.update_apontamento_realtime(data)
      })
  
      frappe.realtime.on('disponibilidade_por_turno', (data) => {
        this.appStore.set_gauge(data)
      })
  
  
    },
  }
  
  </script>
  
  <style></style>